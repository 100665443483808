import React from 'react';
import LogoBlack from './LogoBlack';
import Nav from './Nav';
import MobileMenu from './MobileMenu';
import LogoBox from './LogoBox';
import OffCanvasStyleOne from './OffCanvasStyleOne';

export default class HeaderOne extends React.Component {
    render() {
        return (
            <>
                <header className="main-header main-header-three clearfix">
                    <div className="main-header-three__wrapper">

                        <div className="main-header-three__top clearfix">
                            <div className="container">
                                <div className="main-header-three__top-inner">
                                    <div className="left ">
                                        <em className="" to={process.env.PUBLIC_URL + `/`}>Vous satisfaire est notre devoir</em>
                                    </div>

                                    <div className="right">
                                        <div className="title">
                                            <em>Restez social avec nous !</em>
                                        </div>

                                        <ul className="social-links d-flex">
                                            <li>
                                                <a href="https://www.facebook.com/people/Kaabe-Groupe-Immobilier">
                                                    <span className="icon-facebook-app-symbol"></span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://api.whatsapp.com/send?phone=%2B2250711709793">
                                                    <div className='h-100 w-100 d-flex justify-content-center align-items-center'>
                                                        <img style={{ width: 15 }} src="https://img.icons8.com/ios-filled/50/ffffff/whatsapp--v1.png" />
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://instagram.com/kaabegroupeimmobilier_">
                                                    <div className='h-100 w-100 d-flex justify-content-center align-items-center'>
                                                        <img style={{ width: 15 }} src="https://img.icons8.com/ios-filled/50/ffffff/instagram--v1.png" />
                                                    </div>
                                                </a>
                                            </li>
                                            <li><a href="https://www.linkedin.com/in/kaabe-groupe-511457308/"><span className="icon-linkedin"></span></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="main-header-three__middle py-2">
                            <div className="container">
                                <div className="main-header-three__middle-inner">
                                    <LogoBox />

                                    <div className="contact-info">
                                        <ul>
                                            <li>
                                                <div className="icon-box">
                                                    <span className="icon-pin"></span>
                                                </div>
                                                <div className="text-box">
                                                    <h3>Notre Localisation</h3>
                                                    <p>Riviera Faya, Cocody</p>
                                                </div>
                                            </li>

                                            <li>
                                                <div className="icon-box">
                                                    <span className="icon-email"></span>
                                                </div>
                                                <div className="text-box">
                                                    <h3>Support En Ligne</h3>
                                                    <p className="email"><a href="mailto:info@kaabegroupeimmobilier.com">info@kaabegroupeimmobilier.com</a>
                                                    </p>
                                                </div>
                                            </li>

                                            <li>
                                                <div className="icon-box">
                                                    <span className="icon-phone-call"></span>
                                                </div>
                                                <div className="text-box">
                                                    <h3>Contact Gratuit</h3>
                                                    <p className="number"><a href="tel:2722292074">2722292074 / 0711709793</a></p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="main-header-three__bottom">
                            <div className="container">
                                <div className="main-header-three__bottom-inner">
                                    <div className="main-header-one__bottom-left">
                                        <nav className="main-menu main-menu--1">
                                            <div className="main-menu__inner">
                                                <div className='d-block d-md-none'><MobileMenu /></div>
                                                <div className="stricky-one-logo">
                                                    <LogoBlack />
                                                </div>
                                                <Nav />
                                            </div>
                                        </nav>
                                    </div>


                                    <div className="main-header-one__bottom-right">
                                        <div className="side-content-button-box">
                                            <OffCanvasStyleOne />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </header>

                <div className="stricky-header stricked-menu main-menu">
                    <div className="sticky-header__content"></div>
                </div>


            </>
        )
    }
}
import React, { useEffect, useState } from 'react';

const GaleryItem = ({ data }) => {
    const {
        id,
        title,
        subtitle,
        description,
        icon,
        cover
    } = data

    return (
        <div className="col-xl-3 position-relative">
            {cover ?
                <div className="feature-four__single">
                    <div className="feature-four__single-img">
                        <img src={cover} alt="#" />
                    </div>
                </div>
                :
                <div className="feature-four__single style2 text-center w-100">
                    {icon &&
                        <div className="feature-four__single-icon">
                            <img src={icon} alt="#" />
                        </div>
                    }
                    <div className="feature-four__single-content">
                        <span>{subtitle}</span>
                        <h2>{title}</h2>
                        <p>{description}</p>
                    </div>
                </div>
            }
        </div>
    )
}

const FeatureFour = () => {
    const [data, setData] = useState([])

    const fetchData = async () => {
        const res = await fetch('https://gestion.kaabegroupeimmobilier.com/api/gallery/items')
        const result = await res.json()
        setData(result)
    }

    useEffect(() => {
        fetchData()
    }, []);

    if (!data.length) return

    return (
        <>
            {/* TODO Photo avec un client décrivant son projet, photo avec la maison du client, photo avec un terrain */}
            {/* TODO Formulaire de contact pour permettre au client de laisser un message */}

            <section id="features" className="feature-four text-dark">
                <div className="container">
                    <div className="sec-title text-center">
                        <h4 className="sec-title__title">Votre projet entre de bonnes mains</h4>
                    </div>
                    <div className="row">
                        {data.map((item, index) => (
                            <GaleryItem key={index} data={item} />
                        ))}
                    </div>
                </div>
            </section>
        </>
    )
}

export default FeatureFour;
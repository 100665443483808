import React, { useEffect, useState } from 'react';

const AboutFour = () => {
    let publicUrl = process.env.PUBLIC_URL + '/'

    const [title, settitle] = useState("Construction et promotion immobilière")
    // const [subtitle, setsubtitle] = useState("")
    const [description, setdescription] = useState(`
        <p>La construction est un terme général signifiant l'art et la science de former des objets,
        des systèmes, des organisations, et vient du latin constructionem et de l'ancien français
        construction. Construire est le verbe :</p>`
    )
    const [cover, setCover] = useState(publicUrl + "assets/images/update1.0/about-v3-img1.jpg")
    const [images, setimages] = useState([])

    const fetchData = async () => {
        const res = await fetch('https://gestion.kaabegroupeimmobilier.com/api/website/section')
        const result = await res.json()
        if (!result) return
        const { title, description, medias, cover } = result
        settitle(title)
        setdescription(description)
        setimages(medias)
        if (cover) setCover(cover)
    }

    useEffect(() => {
        fetchData()
    }, []);


    const initCarousel = () => {
        const $ = window.$;
        if ($(".video-popup").length) {
            $(".video-popup").magnificPopup({
                items: images.map(i => ({ src: i })),
                gallery: {
                    enabled: true
                },
                type: 'image',
            });
        }
    }

    useEffect(() => {
        initCarousel()
    }, [images]);

    return (
        <>
            <div className="about-three text-dark">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6">
                            <div className="about-three__img">
                                <div className="inner">
                                    <img src={cover} alt="#" />
                                    <div className="about-three__img-video wow zoomIn" data-wow-delay="100ms">
                                        <button className="video-popup">
                                            <div className="about-three__img-video-icon">
                                                <span className="fa fa-play"></span>
                                                <i className="ripple"></i>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-6">
                            <div className="about-three__content">
                                <div className="sec-title">
                                    <h2 className="sec-title__title">{title}</h2>
                                </div>

                                <div className="about-three__content-inner">
                                    <div className="text1">
                                        <div id="subscribe" dangerouslySetInnerHTML={{ __html: description }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutFour
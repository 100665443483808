import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";

const BrandOne = () => {
    let publicUrl = process.env.PUBLIC_URL + '/'

    const [data, setData] = useState([])

    const fetchData = async () => {
        const res = await fetch('https://gestion.kaabegroupeimmobilier.com/api/partners')
        const result = await res.json()
        setData(result)
    }

    useEffect(() => {
        fetchData()
    }, []);


    console.log("data................", data)

    if (!data.length) return

    return (
        <section className="brand-one-sec">
            <div className="brand-one__bg" style={{ backgroundImage: 'url(' + publicUrl + 'assets/images/backgrounds/brand-v1-bg.png)' }}></div>
            <div className="container">
                <Swiper
                    loop={true}
                    spaceBetween={50}
                    slidesPerView={5}
                    speed={1400}
                    freeMode={true}
                    watchslidesvisibility="true"
                    watchSlidesProgress={true}
                    autoPlay={{ delay: 5000 }}
                    breakpoints={{
                        "0": {
                            spaceBetween: 30,
                            slidesPerView: 1
                        },
                        375: {
                            spaceBetween: 30,
                            slidesPerView: 2
                        },
                        575: {
                            spaceBetween: 30,
                            slidesPerView: 2
                        },
                        767: {
                            spaceBetween: 30,
                            slidesPerView: 3
                        },
                        991: {
                            spaceBetween: 30,
                            slidesPerView: 4
                        },
                        1199: {
                            spaceBetween: 30,
                            slidesPerView: 4
                        }
                    }}
                    className="thm-swiper__slider swiper-container">
                    <div className="swiper-wrapper">
                        {data.map((item, index) => (
                            <SwiperSlide key={index} className="swiper-slide">
                                <Link to={process.env.PUBLIC_URL + `/`}><img src={item.logo} alt="" /></Link>
                            </SwiperSlide>
                        ))}
                    </div>
                </Swiper>
            </div>
        </section>
    )
}

export default BrandOne
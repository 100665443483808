import React from 'react';
import { Link } from 'react-router-dom';

export default class ServiceThree extends React.Component {
    render() {
        let publicUrl = process.env.PUBLIC_URL + '/'
        return (
            <>
                <section id="services" className="services-three">
                    <div className="shape1"><img src={publicUrl + "assets/images/shapes/services-v3-shape1.png"} alt="#" /></div>
                    <div className="shape2 rotate-me"><img src={publicUrl + "assets/images/update1.0/services-v3-shape2.png"} alt="#" /></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="services-three__top">
                                    <div className="sec-title col-md-4">
                                        <div className="sec-title__tagline">
                                            <h6>SERVICES DE L'ENTREPRISE </h6> <span className="right"></span>
                                        </div>
                                        <h2 className="sec-title__title">Nos  services</h2>
                                    </div>
                                    <div className="text-box">
                                        <p>Du lotissement à la construction, nous vous offrons une gamme complète de services immobiliers et de construction pour concrétiser vos projets.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row flex-nowrap overflow-auto">
                            {/* Start Services One Single */}
                            <div className="col-xl-3 col-lg-6 col-md-6">
                                <div className="services-three__single">
                                    <div className="services-three__single__bg" style={{ backgroundImage: 'url(' + publicUrl + 'assets/images/services/services-v1-bg.jpg)' }}></div>
                                    <div className="services-three__single-icon">
                                        <span className="icon-crane"></span>
                                    </div>
                                    <h3 className='text-white'>
                                        {/* <Link to={process.env.PUBLIC_URL + `/general-contracting`}> */}
                                        Lotissement
                                        {/* </Link> */}
                                    </h3>
                                    <p>Transformez vos terrains en quartiers prospères avec notre service de lotissement.</p>
                                    {/* <div className="btn-box">
                                        <Link to={process.env.PUBLIC_URL + `/general-contracting`}><span className="icon-right-arrow"></span></Link>
                                    </div> */}
                                </div>
                            </div>
                            {/* End Services One Single */}

                            {/* Start Services One Single */}
                            <div className="col-xl-3 col-lg-6 col-md-6">
                                <div className="services-three__single">
                                    <div className="services-three__single__bg" style={{ backgroundImage: 'url(' + publicUrl + 'assets/images/services/services-v1-bg.jpg)' }}></div>
                                    <div className="services-three__single-icon">
                                        <span className="icon-surgery"></span>
                                    </div>
                                    <h3 className='text-white'>
                                        {/* <Link to={process.env.PUBLIC_URL + `/general-contracting`}> */}
                                        Gestion immobilière
                                        {/* </Link> */}
                                    </h3>
                                    <p>Libérez-vous des tracas de la gestion immobilière avec notre service dédié.</p>
                                    {/* <div className="btn-box">
                                        <Link to={process.env.PUBLIC_URL + `/general-contracting`}><span className="icon-right-arrow"></span></Link>
                                    </div> */}
                                </div>
                            </div>
                            {/* End Services One Single */}

                            {/* Start Services One Single */}
                            <div className="col-xl-3 col-lg-6 col-md-6">
                                <div className="services-three__single">
                                    <div className="services-three__single__bg" style={{ backgroundImage: 'url(' + publicUrl + 'assets/images/services/services-v1-bg.jpg)' }}></div>
                                    <div className="services-three__single-icon">
                                        <span className="icon-interior-design-1"></span>
                                    </div>
                                    <h3 className='text-white'>
                                        {/* <Link to={process.env.PUBLIC_URL + `/general-contracting`}> */}
                                        Gestion locative
                                        {/* </Link> */}
                                    </h3>
                                    <p>Confiez-nous la gestion de vos biens pour une tranquillité d'esprit totale.</p>
                                    {/* <div className="btn-box">
                                        <Link to={process.env.PUBLIC_URL + `/general-contracting`}><span className="icon-right-arrow"></span></Link>
                                    </div> */}
                                </div>
                            </div>
                            {/* End Services One Single */}

                            {/* Start Services One Single */}
                            <div className="col-xl-3 col-lg-6 col-md-6">
                                <div className="services-three__single">
                                    <div className="services-three__single__bg" style={{ backgroundImage: 'url(' + publicUrl + 'assets/images/services/services-v1-bg.jpg)' }}></div>
                                    <div className="services-three__single-icon">
                                        <span className="icon-surgery"></span>
                                    </div>
                                    <h3 className='text-white'>
                                        {/* <Link to={process.env.PUBLIC_URL + `/general-contracting`}> */}
                                        Construction et promotion immobilière
                                        {/* </Link> */}
                                    </h3>
                                    <p>Faites briller votre projet avec notre service.</p>
                                    {/* <div className="btn-box">
                                        <Link to={process.env.PUBLIC_URL + `/general-contracting`}><span className="icon-right-arrow"></span></Link>
                                    </div> */}
                                </div>
                            </div>
                            {/* End Services One Single */}

                            {/* Start Services One Single */}
                            <div className="col-xl-3 col-lg-6 col-md-6">
                                <div className="services-three__single">
                                    <div className="services-three__single__bg" style={{ backgroundImage: 'url(' + publicUrl + 'assets/images/services/services-v1-bg.jpg)' }}></div>
                                    <div className="services-three__single-icon">
                                        <span className="icon-surgery"></span>
                                    </div>
                                    <h3 className='text-white'>
                                        {/* <Link to={process.env.PUBLIC_URL + `/general-contracting`}> */}
                                        Vente de matériaux de construction
                                        {/* </Link> */}
                                    </h3>
                                    <p>Votre source de matériaux de construction de qualité.</p>
                                    {/* <div className="btn-box">
                                        <Link to={process.env.PUBLIC_URL + `/general-contracting`}><span className="icon-right-arrow"></span></Link>
                                    </div> */}
                                </div>
                            </div>
                            {/* End Services One Single */}

                        </div>
                    </div>
                </section>
            </>
        )
    }
}
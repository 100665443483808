import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Projects = ({ data }) => {

    useEffect(() => {
        // const $ = window.$;
        // if ($(".project-three__carousel").length) {
        //     $(".project-three__carousel").owlCarousel({
        //         loop: true,
        //         margin: 10,
        //         nav: false,
        //         smartSpeed: 500,
        //         autoHeight: false,
        //         autoPlay: true,
        //         dots: false,
        //         autoplayTimeout: 6000,
        //         navText: [
        //             '<i className="fa fa-angle-double-left" aria-hidden="true"></i>',
        //             '<i className="fa fa-angle-double-right" aria-hidden="true"></i>',
        //         ],
        //         responsive: {
        //             0: {
        //                 items: 1,
        //             },
        //             600: {
        //                 items: 1,
        //             },
        //             750: {
        //                 items: 2,
        //             },
        //             1100: {
        //                 items: 3,
        //             },
        //             1350: {
        //                 items: 4,
        //             },
        //         },
        //     });
        // }
        // if ($(".img-popup").length) {
        //     var groups = {};
        //     $(".img-popup").each(function () {
        //         var id = parseInt($(this).attr("data-group"), 10);

        //         if (!groups[id]) {
        //             groups[id] = [];
        //         }

        //         groups[id].push(this);
        //     });

        //     $.each(groups, function () {
        //         $(this).magnificPopup({
        //             type: "image",
        //             closeOnContentClick: true,
        //             closeBtnInside: false,
        //             gallery: {
        //                 enabled: true,
        //             },
        //         });
        //     });
        // }
    }, []);

    return (
        <div className="project-three__carousel owl-carousel owl-theme row mx-auto d-flex justify-content-around">
            {data.map(
                (item, index) =>
                (
                    <div key={index} className="project-three__single col-md-4 mb-5">
                        <div style={{ "max-height": "20em" }} className="project-three__single-img">
                            <img src={item.image} alt="#" />
                            <div className="content-box">
                                {/* <p>constructionnn</p> */}
                                <h3>
                                    <Link className='d-flex' to={process.env.PUBLIC_URL + `/portfolio-details`}>
                                        Acheter
                                        <span className="ps-2 icon-plus-sign"></span>
                                    </Link>
                                </h3>
                            </div>
                        </div>
                        <div className="content-box">
                            <h4 className='mt-3 my-2'>{item.name}</h4>
                            <p>{item.description}</p>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

const ProjectThree = () => {
    const [data, setData] = useState([])

    const fetchData = async () => {
        const res = await fetch('https://gestion.kaabegroupeimmobilier.com/api/projects')
        const result = await res.json()
        setData(result)
    }

    useEffect(() => {
        fetchData()
    }, []);

    return (
        <section id="projects" className="project-three text-dark">
            <div className="container-fluid">
                <div className="sec-title text-center">
                    <div className="sec-title__tagline">
                        <span className="left"></span>
                        <h6>DERNIER PROJET</h6> <span className="right"></span>
                    </div>
                    <h2 className="sec-title__title">Nos projets achevés et <br /> en cours.</h2>
                </div>
                <div className="row">
                    <div className="col-xl-12">
                        <Projects data={data} />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ProjectThree














